<template>
  <div class="pollIndex">
    <Navlid v-if="navshow" :list="navlist"></Navlid>
    <NavBar v-else :list="list" name="详细列表"></NavBar>
    
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'complainlist',
  data() {
    return {
      navshow:false,
      list: [
        {
          name: '待处理',
          path: '/complain/complainlist/pending'
        },
        {
          name: '已处理',
          path: '/complain/complainlist/processed'
        }
      ],
      navlist: [{
        title: '投诉',
        url: '/complain/complainlist/pending'
      }, {
        title: '投诉详情'
      }]
    };
  },
  watch:{
    '$route':{
      handler(e){
if(e.name=='pending' || e.name=='processed'){
        this.navshow = false
      } else {
        this.navshow = true;
      }
      },
      immediate:true
      
    }
  }
};
</script>
